import React from 'react'
import { ReactComponent as Mushroom } from '../assets/mushroom.svg'
import { useMediaQuery } from 'react-responsive'

function PageNumber({ activeSlide, startAnimation }) {
    const isMobileDevice = useMediaQuery({
        query: '(max-width: 600px)'
    })
    let number = ["०१", "०२", "०३", "०४", "०५", "६", "७", "८", "९"];
    let number_class = ["title", "title white", "title white", "title", "title", "title", "title", "title", "title"];
    let title = ['VISION', 'SOUL', 'Services', 'TEAM', 'Connect']
    let title_responsive = ['VISION', 'SOUL', 'Services', 'TEAM', 'TEAM', 'TEAM', 'Connect']
    let classes = [
        'page-number',
        'page-number page-number-black',
        'page-number page-number-black',
        'page-number',
        'page-number hide_page_number']
    let classes_responsive = [
        'page-number',
        'page-number page-number-black',
        'page-number page-number-black',
        'page-number',
        'page-number hide-responsive',
        'page-number hide-responsive',
        'page-number hide_page_number']
    return (<>
        <div className={!isMobileDevice ? classes[activeSlide - 1] : classes_responsive[activeSlide - 1]} id={startAnimation ? "in" : "out"}>
            <h1> {number[activeSlide - 1]} </h1>
            {
                activeSlide !== 0 ? <hr /> : <></>
            }
            {!isMobileDevice ? <h1 className={number_class[activeSlide - 1]}>{title[activeSlide - 1]}
                {
                    activeSlide !== 0 ? <Mushroom width="12px" className="icon" /> : <></>
                }
            </h1> : <h1 className={number_class[activeSlide - 1]}>{title_responsive[activeSlide - 1]}
                    {
                        activeSlide !== 0 ? <Mushroom width="12px" className="icon" /> : <></>
                    }
                </h1>}
        </div>
    </>
    )
}

export default PageNumber
