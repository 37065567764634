import React from 'react'
import './styles/main.scss'
import './styles/flex.scss'
import HomeOne from './HomeOne'


function App() {
  return (
    <HomeOne />
  )
}

export default App
